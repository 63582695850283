<template>
  <div class="title">
    <div class="title-content">
      <img alt="Sasha Sews logo" src="@/assets/logo.jpeg" />
      <h1>Sasha Sews</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=The+Nautigal:wght@700&display=swap");

.title {
  width: 100%;
  display: flex;
  align-items: center;
}
.title-content {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.title-content img {
  align-self: center;
}
h1 {
  font-family: "The Nautigal", cursive;
  color: #ef7ba1;
  font-size: 4em;
  margin: 0 0 0 32px;
  padding: 0;
}
</style>
